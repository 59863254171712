<template>
    <div class="ranking ranking-list" v-if="isDataLoaded">
        <div v-if="showEmptyState">
            <md-empty-state
                md-icon="groups"
                :md-label="lang.turnierplan.emptyStateLabel"
                :md-description="lang.turnierplan.emptyStateDescription">
            </md-empty-state>
        </div>
        <div class="ranking-container" v-else>
            <p class="abbreviationDescription">
                <span>{{lang.listGruppen.abbreviationDescriptionSpiele}}</span>
                <span>{{lang.listGruppen.abbreviationDescriptionSiege}}</span>
                <span>{{lang.listGruppen.abbreviationDescriptionNiederlage}}</span>
            </p>
            <p class="abbreviationDescription">
                <span>{{lang.listGruppen.abbreviationDescriptiongetroffeneBecher}}</span>
                <span>{{lang.listGruppen.abbreviationDescriptionverloreneBecher}}</span>
                <span>{{lang.listGruppen.abbreviationDescriptionBecherdifferenz}}</span>
            </p>
            <div class="ranking-table">
                <div class="card">
                    <span class="table-header">{{lang.listGruppen.rankingHeadline}}</span>
                    <md-table>
                        <md-table-row>
                            <md-table-head md-numeric></md-table-head>
                            <md-table-head></md-table-head>
                            <md-table-head md-numeric>{{lang.listGruppen.abbreviationSpiele}}</md-table-head>
                            <md-table-head md-numeric>{{lang.listGruppen.abbreviationSiege}}</md-table-head> 
                            <md-table-head md-numeric>{{lang.listGruppen.abbreviationNiederlage}}</md-table-head>
                            <md-table-head md-numeric>{{lang.listGruppen.abbreviationgetroffeneBecher}}</md-table-head>
                            <md-table-head md-numeric>{{lang.listGruppen.abbreviationverloreneBecher}}</md-table-head>
                            <md-table-head md-numeric>{{lang.listGruppen.abbreviationBecherdifferenz}}</md-table-head>
                        </md-table-row>
                        <md-table-row v-for="(teilnahme, index) in teilnahmen" :key="index">
                            <md-table-cell md-numeric>{{teilnahme.platzierung}}</md-table-cell>
                            <md-table-cell class="teamName">{{teilnahme.team.teamName}}</md-table-cell>
                            <md-table-cell md-numeric>{{teilnahme.anzahlGespielteSpiele}}</md-table-cell>
                            <md-table-cell class="siege" md-numeric>{{teilnahme.siege}}</md-table-cell>
                            <md-table-cell md-numeric>{{teilnahme.anzahlGespielteSpiele - teilnahme.siege}}</md-table-cell>
                            <md-table-cell md-numeric>{{teilnahme.getroffeneBecher}}</md-table-cell>
                            <md-table-cell md-numeric>{{teilnahme.verloreneBecher}}</md-table-cell>
                            <md-table-cell md-numeric>{{teilnahme.punkteDifferenz}}</md-table-cell>
                        </md-table-row>
                    </md-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store/index'

export default {
    name: 'TurnierRankingComponent',
    props: ['turnierID'],
    created() {
        store.commit("SET_DATA_LOADED_STATUS_TEILNAHMEN", false);
        store.dispatch('getTeilnahmenByTurnierID', this.turnierID);
    },
    computed: {
        lang() {
            return store.getters.getLanguage;
        },
        teilnahmen() {
            return store.getters.getTeilnahmen;
        },
        isDataLoaded() {
            return store.state.teilnahme.isDataLoaded;
        },
        showEmptyState() {
            return (this.teilnahmen.length === 0);
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/base";

@media (min-width: $screen-sm) {
    .ranking-container {
        width: auto;
    }

    .ranking-list .abbreviationDescription {
        margin: 0 20px 5px 20px;
    }

    .ranking-table {
        margin-top: 30px;
    }
}
</style>