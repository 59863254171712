<template>
    <div>
        <TurnierRankingComponent :turnierID="turnierID"/>
    </div>
</template>

<script>
import TurnierRankingComponent from '@/components/Turnier/TurnierRankingComponent.vue'

export default {
    name: 'TurnierRanking',
    props: ['turnierID'],
    components: {
        TurnierRankingComponent
    }
}
</script>
